import { handleResponse, handleError } from "./apiUtils";
import { getIdpAccessToken } from "../sagas/idpSaga";
const baseUrl = process.env.REACT_APP_API_URL;

function getRealAccessToken() {
  const accessToken = getIdpAccessToken();
  if (accessToken) return accessToken;
}

export function getReligtions() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Religion";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.religion,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getIssuerTypes() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/IssuerType";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.issuerType,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getRelationTypes() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/RelationType";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.relationType,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getRoleList() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Role";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.roleList,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getRole(roleId) {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Role/" + roleId;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRoleByType(roleType) {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Role/roleType/" + roleType;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function putRole(role) {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Role";
  return fetch(internalProvidersUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
    body: JSON.stringify(role),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function postRole(role) {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Role";
  return fetch(internalProvidersUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
    body: JSON.stringify(role),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteRole(roleId) {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Role/" + roleId;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getGender() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Enum/gender";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getMaritalStatus() {
  const internalProvidersUrl =
    baseUrl + "/api/v1/LookupData/Enum/marital-status";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getBloodTypes() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Enum/blood-types";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getAllergyTypes() {
  const internalProvidersUrl =
    baseUrl + "/api/v1/LookupData/Enum/allergy-types";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getAllergySeverity() {
  const internalProvidersUrl =
    baseUrl + "/api/v1/LookupData/Enum/allergy-severity";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getIntervals() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Enum/intervals";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}
export function getSpakingLanguages() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/SpeakingLanguage";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.speakingLanguage,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getOccupations() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Occupation";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.occupations,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getDocumentCategory() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/DocumentCategory";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result.documentCategory,
        totalCount: result.totalRecords,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getDrugDoseUnit() {
  const internalProvidersUrl =
    baseUrl + "/api/v1/LookupData/Enum/drug-dose-unit";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getDrugForm() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Enum/drug-form";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getDrugType() {
  const internalProvidersUrl = baseUrl + "/api/v1/LookupData/Enum/drug-type";
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}

export function getModulesByType(userType) {
  const internalProvidersUrl = baseUrl + "/api/v1/module-service/module-by-type/" + userType;
  var url = new URL(internalProvidersUrl);
  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getRealAccessToken(),
    },
  })
    .then(handleResponse)
    .then((result) => {
      return {
        data: result,
        totalCount: result.length,
      };
    })
    .catch(handleError)
    .catch(() => {
      return {
        data: [],
        totalCount: 0,
      };
    });
}
